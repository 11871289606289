<template>
  <form class="login-form" @submit.prevent="newPassword">
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <div
            class="d-inline-flex align-items-center justify-content-center my-2"
          >
            <img
              src="@/assets/img/horizon_logo_color.png"
              class="h-72px"
              alt="Logo do Senar"
            />
          </div>
          <h5 class="mb-0">Portal do Cliente</h5>
          <span class="text-muted">Nova Senha</span>
        </div>
        <div class="mb-3">
          <label class="form-label">Senha</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': v$.password.$errors.length }"
              placeholder="•••••••••••"
              v-model="v$.password.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted" />
            </div>
            <span v-if="v$.password.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span
              v-if="v$.password.minLength.$invalid"
              class="invalid-feedback"
            >
              A senha deve ter pelo menos
              {{ v$.password.minLength.$params.min }} caracteres
            </span>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Confirme a Senha</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              :class="{
                'is-invalid': v$.confirmPassword.$errors.length,
              }"
              placeholder="•••••••••••"
              v-model="v$.confirmPassword.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted" />
            </div>
            <span
              v-if="v$.confirmPassword.sameAsPassword.$invalid"
              class="invalid-feedback"
            >
              As senhas devem ser idênticas
            </span>
          </div>
        </div>
        <div class="mb-3">
          <button
            type="submit"
            class="btn btn-primary w-100"
            title="Criar Nova Senha"
            :disabled="v$.$invalid || disableButton"
          >
            <span v-if="disableButton">
              <i class="ph-spinner spinner" />
            </span>
            <span v-else>Criar Nova Senha</span>
          </button>
        </div>
        <div class="text-center" title="Login">
          <router-link to="/">Login</router-link>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'
import PATHS from '@/router/routesMap'

export default {
  name: 'NewPassword',

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      key: this.$route.params.key,
      password: '',
      confirmPassword: '',
      disableButton: false,
    }
  },

  methods: {
    async newPassword() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch('newPassword', {
            key: this.key,
            password: this.password,
            confirmPassword: this.confirmPassword,
          })

          this.resetForm()

          if (response && response.message) {
            this.$root.$refs.notification.success(response.message)
            this.$router.push(PATHS.AUTH)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        } catch (error) {
          this.resetForm()

          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },

    resetForm() {
      this.disableButton = false
      this.key = ''
      this.password = ''
      this.confirmPassword = ''
      this.v$.$reset()
    },
  },

  validations() {
    return {
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        sameAsPassword: sameAs(this.password),
      },
    }
  },
}
</script>
